#footer-container {
    background-color: #FFD2D5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    margin-top: 25px;
}

#contact-container {
    display: flex;
    flex-direction: row;
    margin: 10px;
}

#contact-content {
    display: flex;
    flex-direction: column;
    color: #B74CEA;
    padding: 10px;
}

#mobile-media-container {
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.footer-icon {
    height: 50px;
    cursor: pointer;
    margin: 10px;
}

.footer-vertical-line {
    border-left: 1px solid black;
    height: 50px;
    margin: 10px;
}