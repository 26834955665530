#timeline-body-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    min-height: 100vh;
    flex-wrap: wrap;
    align-content: center;
    margin-top: 25px;
    margin-bottom: 25px;
}

#timeline-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-left: 15%;
    padding-bottom: 50px;
}

.icon-background {
    border-radius: 50%;
    background-color: white;
    height: 50px;
    width: 50px;
    border: 10px #4F23E2 solid;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.timeline-icon {
    text-align: center;
    align-items: center;
    display: flex;
    position: absolute;
}

.icon-style {
    width: 35px;
}

.timeline-card-content {
    position: relative;
    border-bottom: 5px #4F23E2 solid;
    border-top: 5px #4F23E2 solid;
    max-width: 400px;
    width: 400px;
    word-wrap: break-word;
}

.timeline-title {
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 0;
}

.timeline-date {
    margin-top: 0;
    font-size: larger;
    font-weight: bold;
}

.content-left {
    left: -170px;
    position: relative;
    border-right: #4F23E2 5px solid;
    padding: 20px;
    margin-right: 30px;
}

.content-right {
    left: 300px;
    position: relative;
    border-left: #4F23E2 5px solid;
    padding: 50px;
}

.icon-left {
    position: absolute;
    right: -40px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
}

.icon-right {
    position: absolute;
    left: -40px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
}