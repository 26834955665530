#homepage-body-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh;
}

#children-image {
    width: 50vw;
    margin: 10px;
    align-content: center;
    display: flex;
}

#welcome-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    #download-btn-desktop {
        color: white;
        padding: 5px 10px 5px 10px;
        border-radius: 26px;
        background-color: #4F23E2;
        font-weight: bold;
        text-decoration: none;
    }
}

#welcome-title {
    display: flex;
    margin: 10px;
    align-content: center;
    width: 300px;;
}