#team-body-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 20px;
}

.text-style {
    color: #4F23E2;
    font-size: large;
    margin: 0;
}

#member-container {
    width: 32%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

#member-image {
    width: 200px;
    border-radius: 50%;
    margin-bottom: 10px;
}