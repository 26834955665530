#login-body-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-content: center;
    gap: 50px;
    flex-wrap: wrap;
}

#login-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 300px;
    justify-content: center;
    align-content: center;
}

#schood-logo {
    width: 300px;
    justify-self: center;
}

#logo-container {
    display: grid;
}

#all-container {
    width: 300px;
    height: 400px;
    gap: 25px;
}

#timeline-maintenance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#team-maintenance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#project-maintenance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#team-image {
    max-width: 200px;
}