#logo-title {
    width: 30%;
    text-align: center;
}

#description-body-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

#news-container {
    /* border: 1px gray solid;
    border-radius: 15px; */
    width: 90%;
    /* background-color: lightgrey; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    gap: 15px;
    /* overflow-y: auto;
    height: 500px; */
}

#post-container {
    border: 1px solid gray;
    border-radius: 15px;;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* align-content: center; */
    width: 70%;
}

#post-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
}

#post-title {
    display: flex;
    color: #B74CEA;
    font-size: x-large;
    /* justify-content: center; */
    flex-wrap: wrap;
    font-weight: 600;
    /* text-align: center; */
    margin-left: 20px;
}

#post-content {
    display: flex;
    /* justify-content: center; */
    flex-wrap: wrap;
    /* text-align: center; */
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}